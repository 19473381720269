const Edge = {
  Front: "Front",
  Back: "Back",
  Side: "Side",
  None: "None",
};

const degToRad = (degrees) => {
  var pi = Math.PI;
  return degrees * (pi / 180);
};

class Tower {
  static getDailyOutput(output) {
    return output * 7;
  }

  static getMonthlyOutput(output) {
    return Tower.getDailyOutput(output) * 30;
  }

  static getYearlyOutput(output) {
    return Tower.getDailyOutput(output) * 365;
  }

  constructor(
    width,
    length,
    height,
    output,
    weight,
    maxEWAngle,
    maxNSAngle,
    column,
    row,
  ) {
    this.width = width;
    this.height = height;
    this.length = length;
    this.output = output;
    this.weight = weight;

    this.maxEWAngle = maxEWAngle;
    this.maxNSAngle = maxNSAngle;
    this.column = column;
    this.row = row;
  }

  static _21KWTower(maxEWAngle, maxNSAngle, column, row) {
    return new Tower(
      20,
      19,
      42.4,
      21,
      8975,
      maxEWAngle,
      maxNSAngle,
      column,
      row,
    );
  }

  static _10KWTower(maxEWAngle, maxNSAngle, column, row) {
    return new Tower(
      13,
      13.5,
      29,
      10,
      2688,
      maxEWAngle,
      maxNSAngle,
      column,
      row,
    );
  }

  getRealArea() {
    return this.width * this.length;
  }

  getBackShadow(nextEdge) {
    if (nextEdge === Edge.Back) return this.length;
    return this.height / Math.tan(degToRad(this.maxNSAngle));
  }

  getSideShadow(nextEdge) {
    if (nextEdge === Edge.Side) return this.length;
    return this.width / Math.tan(degToRad(this.maxEWAngle));
  }

  getShadowArea() {
    return this.getSideShadow(Edge.None) * this.getBackShadow(Edge.None);
  }

  capacityPerArea() {
    return this.output / this.getShadowArea();
  }
}

export { Tower, Edge };
