const squareFeetPerAcre = 43560;
const feetPerMiles = 5280;
const milesPerKilometer = 0.621371;
const squareFeetPerSquareMeter = 10.7639;
const feetPerMeter = 3.2808;
const squareFeetPerSquareKilometer = 10763910;

export {
  squareFeetPerAcre,
  feetPerMiles,
  feetPerMeter,
  milesPerKilometer,
  squareFeetPerSquareMeter,
  squareFeetPerSquareKilometer,
};
