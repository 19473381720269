import { Edge, Tower } from "./helpers/Tower.js";
import { squareFeetPerAcre } from "./helpers/Units.js";

class JCalc {
  #regularSolarGeneralArea = 8.3;

  #towers = [];

  static convertAcresToFeet(areaInAcres) {
    return areaInAcres * squareFeetPerAcre;
  }

  static convertFeetToAcres(areaInFeet) {
    return areaInFeet / squareFeetPerAcre;
  }

  static getLandForCapacity(capacity, output) {
    const totalTowers = Math.ceil(capacity / output);
    const rows = Math.ceil(Math.sqrt(totalTowers));
    const columns = Math.ceil(Math.sqrt(totalTowers));
    let t = {};
    if (output <= 10) t = Tower._10KWTower(30, 40, 0, 0);
    else t = Tower._21KWTower(30, 40, 0, 0);
    let columnLength =
      t.getBackShadow(Edge.Back) + columns * t.getBackShadow(Edge.None);
    let rowLength =
      t.getSideShadow(Edge.Side) + rows * t.getSideShadow(Edge.None);
    return {
      width: rowLength,
      length: columnLength,
      area: rowLength * columnLength,
    };
  }

  static packTowers(
    area,
    towerOutput,
    widthOverride,
    heightOverride,
    lengthOverride,
  ) {
    let land = {};
    if (typeof area === "number") {
      let sideLengths = Math.sqrt(JCalc.convertAcresToFeet(area));
      land = {
        width: sideLengths,
        length: sideLengths,
        area: JCalc.convertAcresToFeet(area),
      };
    } else land = area;
    let towers = [];
    let col = 0;
    let row = 0;
    let colDepth = 0;
    let rowDepth = 0;
    while (true) {
      if (towerOutput > 10) var t = Tower._21KWTower(30, 40, col, row);
      else var t = Tower._10KWTower(30, 40, col, row);
      if (widthOverride !== undefined) {
        t.width = widthOverride;
      }
      if (heightOverride !== undefined) {
        t.height = heightOverride;
      }
      if (lengthOverride !== undefined) {
        t.length = lengthOverride;
      }
      if (col === 0 && row === 0) {
        colDepth += t.getBackShadow(Edge.Back);
        rowDepth += t.length;
        if (rowDepth >= land.width || colDepth >= land.length) {
          return "NEL"; // Not Enough Land
        } else {
          rowDepth += t.getSideShadow(Edge.None);
          towers.push(t);
          col += 1;
        }
      } else if (row === 0) {
        rowDepth += t.length;
        if (rowDepth >= land.width) {
          row += 1;
          col = 0;
          rowDepth = 0;
        } else {
          rowDepth += t.getSideShadow(Edge.None);
          towers.push(t);
          col += 1;
        }
      } else if (col === 0) {
        colDepth += t.getBackShadow(Edge.None);
        rowDepth += t.length;
        if (colDepth >= land.length) {
          return towers;
        } else {
          rowDepth += t.getSideShadow(Edge.None);
          towers.push(t);
          col += 1;
        }
      } else {
        rowDepth += t.length;
        if (rowDepth >= land.width) {
          row += 1;
          col = 0;
          rowDepth = 0;
        } else {
          rowDepth += t.getSideShadow(Edge.None);
          towers.push(t);
          col += 1;
        }
      }
    }
  }

  static fromScope(scope, energyRate, location, towerOutput) {
    if (towerOutput > 10) towerOutput = 21;
    else towerOutput = 10;
    return new JCalc(
      scope,
      JCalc.getLandForCapacity(scope, towerOutput),
      energyRate,
      location,
      towerOutput,
    );
  }

  static fromArea(area, energyRate, location, towerOutput) {
    if (towerOutput > 10) towerOutput = 21;
    else towerOutput = 10;
    let land = {};
    if (typeof area === "number") {
      let sideLengths = Math.sqrt(JCalc.convertAcresToFeet(area));
      land = {
        width: sideLengths,
        length: sideLengths,
        area: JCalc.convertAcresToFeet(area),
      };
    } else land = area;
    return new JCalc(
      JCalc.packTowers(area, towerOutput).length * towerOutput,
      land,
      energyRate,
      location,
      towerOutput,
    );
  }

  static kwToActual(kw) {
    if (kw > 1000) {
      return (kw / 1000.0).toFixed(2) + " MW";
    } else if (kw > 1000.0 * 1000.0) {
      return (kw / (1000.0 * 1000.0)).toFixed(2) + " GW";
    }
    return kw + " KW";
  }

  static kwhToActual(kwh) {
    if (kwh > 1000.0) {
      return (kwh / 1000.0).toFixed(2) + " MWh";
    } else if (kwh > 1000.0 * 1000.0) {
      return (kwh / (1000.0 * 1000.0)).toFixed(2) + " GWh";
    }
    return kwh + " KWh";
  }

  constructor(energyUsage, land, energyRate, location, towerOutput) {
    if (typeof land === "number") {
      let sideLengths = Math.sqrt(JCalc.convertAcresToFeet(land));
      this.land = {
        width: sideLengths,
        length: sideLengths,
        area: JCalc.convertAcresToFeet(land),
      };
    } else this.land = land;
    this.energyUsage = energyUsage;
    this.energyRate = energyRate;
    this.location = location;
    if (towerOutput > 10) this.towerOutput = 21;
    else this.towerOutput = 10;
  }

  getOptimalTowerSize() {
    if (this.towerOutput > 10) {
      var w = 8.5;
      var l = 19;
      var h = 42.4;
    } else {
      var w = 6;
      var l = 13.5;
      var h = 29;
    }
    let previous = [];
    while (true) {
      let test = this.cubePackTowers(w, h, l);
      if (test.length >= previous.length) {
        previous = test;
      } else {
        return previous;
      }
      w += 1;
      h -= 1;
    }
  }

  cubePackTowers(widthOverride, heightOverride, lengthOverride) {
    this.#towers = JCalc.packTowers(
      this.land,
      this.towerOutput,
      widthOverride,
      heightOverride,
      lengthOverride,
    );
  }

  getAcresAvailable() {
    return JCalc.convertFeetToAcres(this.land.area);
  }

  ensureTowersPacked() {
    if (this.#towers.length === 0) this.cubePackTowers();
  }

  getNumTowers() {
    this.ensureTowersPacked();
    return this.#towers.length;
  }

  getTotalCapacity() {
    this.ensureTowersPacked();
    return this.#towers.length * this.towerOutput;
  }

  getCapacityPerAcre() {
    this.ensureTowersPacked();
    let area = JCalc.convertFeetToAcres(this.land.area);
    return (this.towerOutput * this.#towers.length) / area;
  }

  getAcrePerMW() {
    return (
      this.getTotalCapacity() / 1000 / JCalc.convertFeetToAcres(this.land.area)
    );
  }

  getLandFor2D() {
    return this.#regularSolarGeneralArea * (this.getTotalCapacity() / 1000);
  }

  getLandConserved() {
    return this.getLandFor2D() - this.getAcresAvailable();
  }

  getKWNeeded() {
    return this.energyUsage / (7 * 25);
  }

  getMWNeeded() {
    return this.energyUsage / (7 * 25) / 1000;
  }

  getProjectCost(price, limited) {
    if (limited) {
      this.ensureTowersPacked();
      return price * (this.getTotalCapacity() * 1000);
    }
    return price * (this.energyUsage * 1000);
  }

  getProjectCostWithReap(price, limited) {
    let val = this.getProjectCost(price, limited);
    if (val > 2000000) return val - 1000000;
    return val / 2;
  }

  getTaxCreditForProjectCost(price, limited) {
    return this.getProjectCost(price, limited) * 0.4;
  }

  getProjectCostWithTaxCredit(price, limited) {
    return (
      this.getProjectCost(price, limited) -
      this.getTaxCreditForProjectCost(price, limited)
    );
  }

  getProjectCostWithIncentives(price, limited) {
    return (
      this.getProjectCostWithReap(price, limited) -
      this.getTaxCreditForProjectCost(price, limited)
    );
  }

  getMonthlyEnergyCost() {
    return this.energyRate * (this.energyUsage * 24);
  }

  getYearlyEnergyCost() {
    return this.energyRate * (this.energyUsage * 24) * 12;
  }

  getMonthlyCostOffset() {
    return this.getTotalCapacity() * 7 * 30 * this.energyRate;
  }

  getYearlyCostOffset() {
    return this.getTotalCapacity() * 7 * 30 * 12 * this.energyRate;
  }

  getPaybackPeriod(price, reap, tax, limited) {
    console.log(this.getTrueProjectCost(price, reap, tax, limited));
    console.log(this.getYearlyCostOffset());
    return (
      this.getTrueProjectCost(price, reap, tax, limited) /
      this.getYearlyCostOffset()
    );
  }

  getTrueProjectCost(price, reap, tax, limited) {
    if (reap && tax) {
      return this.getProjectCostWithIncentives(price, limited);
    } else if (reap) {
      return this.getProjectCostWithReap(price, limited);
    } else if (tax) {
      return this.getProjectCostWithTaxCredit(price, limited);
    }
    return this.getProjectCost(price, limited);
  }

  getLifetimeOutput(years) {
    this.ensureTowersPacked();
    let yearlyOutput = Tower.getYearlyOutput(
      this.towerOutput * this.#towers.length,
    );
    let total = 0;
    while (years > 1) {
      years--;
      total += yearlyOutput;
      yearlyOutput = yearlyOutput * 0.995;
    }
    return total;
  }

  getLCOE(price, reap, tax, limited) {
    return (
      this.getTrueProjectCost(price, reap, tax, limited) /
      this.getLifetimeOutput(20)
    );
  }

  getRealCost() {}
}

var test = Tower._21KWTower(21, 36, 40, 0, 0);
var test2 = new JCalc(
  100,
  { width: 620, length: 390, area: 620 * 390 },
  0.05,
  {
    latitude: 0,
    longitude: 0,
  },
  21,
);

var test3 = JCalc.fromScope(100, 0.14, null, 10);
var test4 = JCalc.fromScope(220, 0.14, null, 20);

// console.log(JCalc.convertFeetToAcres(test2.getLandForCapacity(3300)));
// console.log(JCalc.convertFeetToAcres(620 * 390));
// console.log(test2.cubePackTowers().length);
// console.log(test2.getOptimalTowerSize().length * 21);
//console.log(test3.getProjectCost());
// let test3real = JCalc.convertFeetToAcres(JCalc.getLandForCapacity(220, 21));
// let test4real = JCalc.convertFeetToAcres(JCalc.getLandForCapacity(150, 10));
// let _2dtest3 = JCalc.convertFeetToAcres(test3.getLandFor2D());
// let _2dtest4 = JCalc.convertFeetToAcres(test4.getLandFor2D());

// console.log(test3real);
// console.log(test4real);
// console.log(_2dtest3);
// console.log(_2dtest4);
// console.log("Total Real: " + (test3real + test4real));
// console.log("Total 2D: " + (_2dtest3 + _2dtest4));
export default JCalc;
